
$(document).ready(function () {
    var phone = $('#phone').val();
    var password = $('#password').val();

    if (phone.length > 0 && password.length > 0) {
        $('#btn-login').addClass('btn-active');
    } else {
        $('#btn-login').removeClass('btn-active');
    }
    $('#phone').keyup(function () {
        $('#error-login').html('');
        var phone = $('#phone').val();
        var password = $('#password').val();

        if (phone.length > 0 && password.length > 0) {
            $('#btn-login').addClass('btn-active');
        } else {
            $('#btn-login').removeClass('btn-active');
        }
    });
    $('#password').keyup(function () {
        $('#error-login').html('');
        var phone = $('#phone').val();
        var password = $('#password').val();

        if (phone.length > 0 && password.length > 0) {
            $('#btn-login').addClass('btn-active');
        } else {
            $('#btn-login').removeClass('btn-active');
        }
    });
    $('#form_login .item-eye-off').on('click', function () {
        if ($('#form_login .eye-off').attr("src") == '/image/eye.svg') {
            $('#form_login .eye-off').attr("src", "/image/eye-off.svg");
            $('#password').prop('type', 'password');
        } else {
            $('#form_login .eye-off').attr("src", "/image/eye.svg");
            $('#password').prop('type', 'text');
        }
    });

    var count_click = 0;
    $('#remember').on('click', function () {
        count_click += 1;
        if (count_click % 2 == 0) {
            $(this).prop('checked', false);
            $('#img_check_remember').attr('src', '/image/unchecked.svg');
        } else {
            $(this).prop('checked', true);
            $('#img_check_remember').attr('src', '/image/check.svg');
        }
    });

    //Modal quên mật khẩu
    $('#forgetPassword .phone_confirm').keyup(function (e) {
        if (this.value.length > 1) {
            $('#forgetPassword #btnForgetPassword').addClass('btn-active');
        } else {
            $('#forgetPassword #btnForgetPassword').removeClass('btn-active');
        }
    });
    $('#forgetPassword').on('click', '#btnForgetPassword', function () {
        var item = $(this);
        var old_curent = item.html();
        item.html('<i class="fa fa-spinner fa-spin"></i>' + old_curent);
        item.prop('disabled', true);

        var phone = $('#forgetPassword input[name=phone]').val();

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url: route_forget_password,
            type: 'POST',
            dataType: 'json',
            data: {
                phone: phone
            },
            success: function (result) {
                item.html(old_curent);
                item.prop('disabled', false);

                if (result.success == true) {
                    if (result.redirect) {
                        window.location = result.redirect;
                    }

                    let phone_html = phone.slice(0, 3) + '*****' + phone.slice(-2);

                    $('#confirmOTP input[name=phone]').val(phone);
                    $('#confirmOTP input[name=contact_sfid]').val(result.data.contact_id);

                    $('#confirmOTP .phone').html(phone_html);
                    $('#confirmOTP').modal('show');
                    $('#confirmOTP #n0').focus();

                    var time_active_otp = result.data.time_active_otp;
                    countDown(time_active_otp);

                    $('#forgetPassword .message_error').html('');
                    $('#forgetPassword input[name=phone]').val('');
                    $('#forgetPassword').modal('hide');
                } else {
                    $('#forgetPassword .message_error').html('Số điện thoại không đúng');
                }
            },
            error: function () {

            },
        });
    });

    //Modal xác nhận mã OTP
    $('#confirmOTP .list_input_otp').find('.input-otp').each(function () {
        $(this).on('keyup', function (e) {
            if (e.keyCode === 8 || e.keyCode === 37) {
                let previous = $(this).data('previous');
                $('#n' + previous).focus();

                $('#confirmOTP #btnConfirmOTP').removeClass('btn-active');
            } else {
                if (this.value.length === this.maxLength) {
                    let next = $(this).data('next');
                    $('#n' + next).focus();

                    if (next == 6) {
                        $('#confirmOTP #btnConfirmOTP').addClass('btn-active');
                    } else {
                        $('#confirmOTP #btnConfirmOTP').removeClass('btn-active');
                    }
                }
            }
        });
    });

    $('#confirmOTP').on('click', '#btnConfirmOTP', function () {
        var item = $(this);
        var old_curent = item.html();
        item.html('<i class="fa fa-spinner fa-spin"></i> ' + old_curent);
        item.prop('disabled', true);

        var otp = $("#confirmOTP input[name^='otp']").map(function (idx, ele) {
            return $(ele).val();
        }).get();

        var phone = $('#confirmOTP input[name=phone]').val();

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url: route_confirm_otp,
            type: 'POST',
            dataType: 'json',
            data: {
                otp: otp,
                phone: phone
            },
            success: function (result) {
                item.html(old_curent);
                item.prop('disabled', false);

                if (result.success == true) {
                    $('#confirmOTP input[name=contact_sfid]').val('');
                    $('#confirmOTP input[name=phone]').val('');
                    $('#confirmOTP input[name=otp\\[\\]]').val('');
                    $('#confirmOTP .message_error').html('');
                    $('#confirmOTP').modal('hide');

                    $('#changePassOTP input[name=contact_sfid]').val(result.data.contact_id);
                    $('#changePassOTP').modal('show');
                } else {
                    $('#confirmOTP .message_error').html(result.message);
                }
            },
            error: function () {

            },
        });
    });
    $('#confirmOTP').on('click', '#sentAgainOTP', function () {
        var item = $(this);
        var old_curent = item.html();
        item.html('<i class="fa fa-spinner fa-spin"></i> ' + old_curent);
        item.prop('disabled', true);

        var contact_sfid = $('#confirmOTP input[name=contact_sfid]').val();
        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url: route_send_otp,
            type: 'POST',
            dataType: 'json',
            data: {
                contact_sfid: contact_sfid,
            },
            success: function (result) {
                item.html(old_curent);
                item.prop('disabled', false);

                var time_active_otp = result.data.time_active_otp;
                countDown(time_active_otp);
            },
            error: function () {
            },
        });
    });

    var x_countDown;
    function countDown(seconds = 60) {
        clearInterval(x_countDown);
        $('#confirmOTP #countDownOTP').removeClass('d-none');
        $('#confirmOTP #messageSentAgainOTP').addClass('d-none');

        x_countDown = setInterval(function () {
            seconds -= 1;
            $('#confirmOTP #countDownOTP').find('.seconds').html(seconds);

            if (seconds <= 0) {
                clearInterval(x_countDown);

                $('#confirmOTP #countDownOTP').addClass('d-none');
                $('#confirmOTP #messageSentAgainOTP').removeClass('d-none');
                $('#confirmOTP #countDownOTP').find('.seconds').html('');
            }
        }, 1000);
    }

    var myModalElConfirmOTP = document.getElementById('confirmOTP');
    myModalElConfirmOTP.addEventListener('hidden.bs.modal', function (event) {
        clearInterval(x_countDown);
        $('#confirmOTP #countDownOTP').find('.seconds').html('');
        $('#confirmOTP input[name=otp\\[\\]]').val('');
    })

    //Modal đặt lại mật khẩu mới
    $('#changePassOTP .item-eye-off').on('click', function () {
        if ($(this).find('.eye-off').attr("src") == '/image/eye.svg') {
            $(this).find('.eye-off').attr("src", "/image/eye-off.svg");
            $(this).closest('.form-group').find('.show_pass').prop('type', 'password');
        } else {
            $(this).find('.eye-off').attr("src", "/image/eye.svg");
            $(this).closest('.form-group').find('.show_pass').prop('type', 'text');
        }
    });
    $('#changePassOTP #password_new').keyup(function () {
        var password_new = $('#changePassOTP #password_new').val();
        var password_new_confirmation = $('#changePassOTP #password_new_confirmation').val();

        if (password_new.length > 0 && password_new_confirmation.length > 0) {
            $('#changePassOTP #btnChangePassOTP').addClass('btn-active');
        } else {
            $('#changePassOTP #btnChangePassOTP').removeClass('btn-active');
        }
    });
    $('#changePassOTP #password_new_confirmation').keyup(function () {
        var password_new = $('#changePassOTP #password_new').val();
        var password_new_confirmation = $('#changePassOTP #password_new_confirmation').val();

        if (password_new.length > 0 && password_new_confirmation.length > 0) {
            $('#changePassOTP #btnChangePassOTP').addClass('btn-active');
        } else {
            $('#changePassOTP #btnChangePassOTP').removeClass('btn-active');
        }
    });
    $('#changePassOTP').on('click', '#btnChangePassOTP', function () {
        var item = $(this);
        var old_curent = item.html();
        item.html('<i class="fa fa-spinner fa-spin"></i> ' + old_curent);
        item.prop('disabled', true);

        var contact_sfid = $('#changePassOTP input[name=contact_sfid]').val();
        var password = $('#changePassOTP input[name=password_new]').val();
        var password_confirmation = $('#changePassOTP input[name=password_new_confirmation]').val();

        if (password.length == 0) {
            $('#changePassOTP .message_error').html('Chưa nhập mật khẩu');
            item.html(old_curent);
            item.prop('disabled', false);
            return false;
        } else if (password_confirmation.length == 0) {
            $('#changePassOTP .message_error').html('Chưa nhập mật khẩu xác thực');
            item.html(old_curent);
            item.prop('disabled', false);
            return false;
        }

        if (password.length < 5) {
            $('#changePassOTP .message_error').html('Mật khẩu phải có ít nhất 5 ký tự');
            item.html(old_curent);
            item.prop('disabled', false);
            return false;
        }

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url: route_change_password_otp,
            type: 'POST',
            dataType: 'json',
            data: {
                contact_sfid: contact_sfid,
                password: password,
                password_confirmation: password_confirmation
            },
            success: function (result) {
                item.html(old_curent);
                item.prop('disabled', false);

                if (result.success == true) {
                    $('#changePassOTP input[name=contact_sfid]').val('');
                    $('#changePassOTP input[name=password_new]').val('');
                    $('#changePassOTP input[name=password_new_confirmation]').val('');
                    $('#changePassOTP').modal('hide');

                    $('#changePassOTPSuccess').modal('show');
                } else {
                    $('#changePassOTP .message_error').html('Xác nhận mật khẩu không đúng');
                }
            },
            error: function () {
                item.html(old_curent);
                item.prop('disabled', false);
            },
        });
    });

    $('#changePassOTP button.btn-close').on('click', function () {
        $('#changePassOTP input[name=password_new]').val('');
        $('#changePassOTP input[name=password_new_confirmation]').val('');
        $('#changePassOTP .message_error').html('');
    });
});
